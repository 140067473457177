// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

var PublicSuffixList = require('psl');
var domainObject = PublicSuffixList.parse(document.domain);
// {
//     "input": "www.shopgrid.io",
//     "tld": "io",
//     "sld": "iporto",
//     "domain": "shopgrid.io",
//     "subdomain": "www",
//     "listed": true
// }

export const environment = {
  production: true,
  app_name: 'Shopgrid',

  // PRD
  web_url: 'https://shopgrid.com.br',
  app_url: 'https://app.shopgrid.com.br',
  api_url: 'https://api.shopgrid.com.br/api/',
  api_doc: 'https://api.shopgrid.com.br/docs',
  api_url_auth: 'https://api.shopgrid.com.br/api/',
  api_url_account: 'https://api.shopgrid.com.br/api/',

  cookie_domain: '.shopgrid.com.br',

  services: {
    platform: 'https://platform.shopgrid.com.br',
  },

  http_garbage_collection: null,

  marketplace: {
    hostname: domainObject.domain,
    domainObject: domainObject,
  },

  page: {
    url: document.URL,
    domain: document.domain,
    expression: document.location.pathname,
  },
};
