<ng-container *ngIf="profile?.id">
  <div class="main-container flex flex-col px-4">
    <div class="relative flex justify-between pt-4">
      <div class="flex lg:static xl:col-span-2 mx-1">
        <div class="flex-shrink-0 flex items-center">
          <a [routerLink]="['/guard']">
            <img class="block w-[60px]" src="/assets/images/logo.png" alt="Shopgrid" />
          </a>
        </div>
      </div>
      <div class="flex items-center justify-end col-span-4 mx-4 space-x-3 text-black">
        <ng-container *ngIf="profile.customer.order == null && profile.customer.order_awaiting_payment.length == 0">
          <div class="flex flex-col">
            <a href="{{environment.web_url}}/pricing/platform" class="text-sm font-medium"> {{profile.name}}, <span class="underline underline-offset-4"> Seja Premium</span> </a>
            <small class="text-xxs">
              <a [routerLink]="['/guard/customer']"> {{'@'}}{{profile.customer.id}} </a>
            </small>
          </div>
        </ng-container>

        <ng-container *ngIf="profile.customer.order != null">
          <ng-container *ngIf="profile.customer.order.plan != null">
            <div class="flex flex-col">
              <a [routerLink]="['/guard/customer/order/active']" class="hidden lg:block text-sm font-medium underline underline-offset-4"> {{profile.customer.order.plan.name}} </a>
              <small class="text-xxs">
                <a [routerLink]="['/guard/customer']"> {{'@'}}{{profile.customer.id}} </a>
              </small>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="profile.customer.order_awaiting_payment.length > 0">
          <a [routerLink]="['/guard/customer/financial/invoice/'+profile.customer.order_awaiting_payment[0].invoice_activation_plan.customer_financial_invoice_id+'/view']" class="hidden sm:inline-flex text-white bg-[#050708] hover:bg-[#050708]/80 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 me-2 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 me-2 -ms-1">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>

            Ativar minha compra
          </a>
        </ng-container>

        <ng-container *ngIf="profile.url_avatar === null">
          <a [routerLink]="['/guard/customer']">
            <span class="inline-block h-10 w-10 flex-none rounded-full overflow-hidden bg-gray-100">
              <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path fill="#4f46e5" d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          </a>
        </ng-container>

        <ng-container *ngIf="profile.url_avatar !== null">
          <a [routerLink]="['/guard/customer']">
            <img src="{{profile.url_avatar}}" alt="{{profile.name}}" class="h-10 w-10 flex-none rounded-full object-cover" />
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
